
























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import moment from 'moment'
import ImgView from '@/components/ImgView/index.vue'
import { getDictName } from '@/utils/dict'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)
@Component({
  components: {
    Table,
    Pagination,
    ImgView
  }
})
export default class IdentifyRecord extends Vue {
  $constants: any
  // 查询条件
  carGroupsDeviceId = ''
  status = ''
  searchModel = {
    key: 'startTime',
    value: null
  }
  searchModel2 = {
    key: 'carNo',
    value: null
  }
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  listLoading = false
  // 设备组
  carGroups: any = []
  tableHeader = [
    {
      key: 'photo',
      name: '识别照片',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '车牌号',
      width: '13%'
    },
    {
      key: 'door',
      name: '识别车闸',
      width: '13%',
      isSpecial: true
    },
    {
      key: 'isStop',
      name: '是否拦截',
      width: '10%'
    },
    {
      key: 'resType',
      name: '拦截原因',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'status',
      name: '识别结果',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'time',
      name: '识别时间',
      width: '13%'
    }
  ]
  // status：  0 门岗没处理  1 门岗通过  2门岗已拒绝
  // 拦截原因：0 有效车     1 车辆到期  2 车位已满  3 没有进出权限  -1 陌生车辆（没有处理）；
  // 识别结果 ：-1 陌生车辆  1系统车辆  2预约访客车辆
  resTypeDicts = [
    {
      value: '0',
      name: '有效车'
    },
    {
      value: '1',
      name: '车辆到期'
    },
    {
      value: '2',
      name: '车位已满'
    },
    {
      value: '3',
      name: '没有进出权限'
    },
    {
      value: '-1',
      name: '陌生车辆'
    }
  ]
  statusDicts = [
    {
      value: 0,
      name: '门岗未处理'
    },
    {
      value: 1,
      name: '门岗通过'
    },
    {
      value: 2,
      name: '门岗拒绝'
    }
  ]
  tableData = [] as any
  imgUrl = ''
  pickMinDate = ''
  pickOptions = {
    disabledDate: (v: any) => {
      if (this.pickMinDate !== '') {
        let maxTime = moment(this.pickMinDate).add(3, 'months')
        const minTime = moment(this.pickMinDate).subtract(3, 'months')
        if (maxTime > moment().endOf('day')) {
          maxTime = moment().endOf('day')
        }
        return moment(v) > maxTime || moment(v) < minTime
      }
      return moment(v) > moment().endOf('day')
    },
    onPick: (date: any) => {
      this.pickMinDate = date.minDate
      if (date.maxDate) {
        this.pickMinDate = ''
      }
    }
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  mounted() {
    this.getCarGroups()
    this.getCarTravelLog()
  }

  // 获取设备离开
  getCarGroups() {
    this.$api.common
      .getAuthGroups({
        type: 2,
        size: '-1'
      })
      .then((res: any) => {
        if (res.data.code === '200' && res.data.success) {
          this.carGroups = res.data.data
        }
      })
  }

  searchFn() {
    this.listQuery.page = 1
    this.getCarTravelLog()
  }

  getCarTravelLog() {
    this.listLoading = true
    this.$api.car
      .getCarTravelLog({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        deviceId:
          this.carGroupsDeviceId !== '' ? Number(this.carGroupsDeviceId) : '',
        startTime: this.searchModel.value && (this.searchModel.value as any)[0],
        endTime: this.searchModel.value && (this.searchModel.value as any)[1],
        [this.searchModel2.key]: this.searchModel2.value,
        hasHoldUp: this.status
      })
      .then((res: any) => {
        this.listLoading = false
        this.tableData = []
        const data = res.data
        this.tableData = data.data.map((item: any) => {
          if (item.resType === 0) {
            item.isStop = '未拦截'
          } else {
            item.isStop = '已拦截'
          }
          return item
        })
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }
}
